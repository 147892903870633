<template>
  <div>
    <Header />
    <div class="episode_main_wrap">
      <slot />
      <!--<Footer />-->
    </div>
    <SharePopup />
  </div>
</template>

<script setup>
import { useHeaderStore } from '@/stores/header';
import { onMounted, onBeforeUnmount, watch } from 'vue';

const store = useHeaderStore()
const { locale } = useI18n()
const { $addBodyClassByi18n } = useNuxtApp();

watch(locale, (newVal, oldVal) => {
  $addBodyClassByi18n(newVal, oldVal);
})

onMounted(() => {
  $addBodyClassByi18n(locale.value, '');
  store.showGnb(false);
  window.addEventListener('resize', appHeight);
  appHeight();
})

onBeforeUnmount(() => {
  window.removeEventListener('resize', appHeight);
})

function appHeight() {
  const doc = document.documentElement;
  doc.style.setProperty('--app-height', `${window.innerHeight}px`);
}
</script>

<style lang="scss" scoped>
main {
  position: relative;
  height: 100%;
}

.episode_main_wrap {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #000;
  min-height: 100%;
  height: 100vh;
  height: var(--app-height);

  main {
    overflow: hidden;
    flex-grow: 1;

    &::before {
      content: '';
      position: absolute;
      width: 100%;
      height: 160px;
      top: 0px;
      background: linear-gradient(180deg, RGBA(0, 0, 0, 0.7) 0%, RGBA(0, 0, 0, 0) 100%);
      z-index: 0;
      pointer-events: none;
    }
  }
}
</style>